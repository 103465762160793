import React from "react"
import { graphql } from "gatsby"

import {Container, Row, Col } from "react-bootstrap"

import Layout from "../components/layout"
import SEO from "../components/seo"
import SpotlightArticle from "../components/SpotlightArticle"
import devSpotlight from "../media/img/development/dev-spotlight.jpg"
import Wiedenism from "../components/Wiedenism"

export default function LearnAndGrow(props) {
  return (
  <Layout pageInfo={{ pageName: "index" }}>
    <SEO title="Your Development" />
    <SpotlightArticle tags={`Learn and Grow`} title={`The secret to constant growth is constant learning. Here you’ll find resources that help you skill up for life. Whether you want to browse through decks and blogs on varied topics, take a look at some cool work from different offices in the network or seek out some inspo - we’ve got you covered.`} bgImg={devSpotlight}/>
    <section>
      <Container>
        <Row>
            {props.data.allIntranetPages.edges.map(pagesThumb => {
              return(
                <Col xs={12} lg={4}>
                  <div className="news--content">
                      <a rel="noreferrer" href={pagesThumb.node.slug}>
                        <div className="image">
                          <img src={pagesThumb.node.image} alt="" />
                        </div>
                        <div className="header mt-3">
                          {pagesThumb.node.title}
                        </div>
                        <small>View</small>
                      </a>
                  </div>
                </Col>
              )
            })}  
            {props.data.allIntranetPost.edges.map(article => {
                return(
                  <Col xs={12} lg={4}>
                    <div className="news--content">
                      <a target="_blank" rel="noreferrer" href={article.node.link}>
                        <div className="image">
                          <img src={article.node.image} alt="" />
                        </div>
                        <div className="header mt-3">
                          {article.node.title}
                        </div>
                        <small>View</small>
                      </a>
                    </div>
                  </Col>

                )
              }
            )}
          </Row>
        </Container>

      <Wiedenism />
    </section>
  </Layout>
  )
}


export const query = graphql`
  query LearnAndGrow {
    allIntranetPost(filter: {category: {eq: "Learning & Development"}}) {
      edges {
        node {
          category
          excerpt
          image
          link
          title
          type
        }
      }
    }
    allIntranetPages(filter: {category: {eq: "lnd"}}) {
      edges {
        node {
          slug
          title
          image
          excerpt
          category
        }
      }
    }
  }
  
`