import React from 'react'
import Marquee from 'react-double-marquee'

var marqueeSettings = {
  direction: "left"
}
export default function Wiedenism() {
    return (
        <div className="home__news__Marquee">
            <Marquee {...marqueeSettings}>
                Walk in stupid • The work comes first • Fail Harder • Independence is everything • Walk in stupid • The work comes first • Fail Harder • Independence is everything &bull; Walk in stupid • The work comes first • Fail Harder • Independence is everything • Walk in stupid • The work comes first • Fail Harder • Independence is everything &bull; Walk in stupid • The work comes first • Fail Harder • Independence is everything • Walk in stupid • The work comes first • Fail Harder • Independence is everything 
            </Marquee>
        </div>
    )
}
